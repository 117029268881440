
import axios from 'axios';
export default {
    
        async createLoans(params)  {
            return await axios.post(`loans/create` , params)
        },
        async createLoansList(params)  {
            return await axios.post(`loans/create/list` , params)
        },
        async updateLoansColumn(column , value , data)  {
            return await axios.put(`loans/update_list?${column}=${value}` , data)
        },
        async deleteLoansList(list)  {
            return await axios.delete(`loans/delete_list` , {
                data: {
                    list:list
                }
            })
        },
        async reportLoans(columns)  {
            return await axios.get(`loans/report?${columns}`)
        },
        async getAllLoans()  {
            return await axios.get(`loans/all`)
        },
        async getOneLoans(loan_id)  {
            return await axios.get(`loans/all/${loan_id}`)
        },
        async getLoansByColumn(column , value)  {
            return await axios.get(`loans/filter?column=${column}&value=${value}`)
        },
        async deleteLoans(loan_id)  {
            return await axios.delete(`loans/delete/${loan_id}`)
        },
        async updateLoans(loan_id , params)  {
            return await axios.put(`loans/update/${loan_id}` , params)
        },
}
        