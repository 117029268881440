
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <v-layout row wrap px-4 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.loans.list_path">
                            <template v-slot:item="{item}">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                    {{ item.title }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <form @submit.prevent="addLoans" autocomplete="off">
                    <v-layout row wrap>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="customers" v-model="loans.customer_id" dense  filled outlined item-text="customer_name"
                                item-value="customer_id" :return-object="false" :label="$store.getters.language.data.loans.customer_name" @change="readLoans()">
                            </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="users" v-model="loans.user_id" dense  filled outlined item-text="user_name"
                                item-value="user_id" :return-object="false" :label="$store.getters.language.data.loans.user_name" :disabled="$store.getters.user.user_role != 'admin'">
                            </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="loans.loan_date" type="date" :label="$store.getters.language.data.loans.loan_date" dense
                                class="mx-1"  filled outlined required>
                            </v-text-field>
                        </v-flex>
                    
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="loans.loan_amount" type="text" :label="$store.getters.language.data.loans.loan_amount" dense
                                class="mx-1"  filled outlined required>
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="accounts" v-model="loans.account_id" dense  filled outlined item-text="account_name"
                                item-value="account_id" :return-object="false" :label="$store.getters.language.data.loans.account_name">
                            </v-select>
                        </v-flex>
                    
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-textarea auto-grow rows="1" v-model="loans.loan_note" type="textarea" :label="$store.getters.language.data.loans.loan_note" dense
                                class="mx-1"  filled outlined>
                            </v-textarea>
                        </v-flex>

                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn color="primary" :loading="loading_btn" type="submit" :disabled="buttonDisable">{{$store.getters.language.data.loans.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers" v-model="selected_rows"  :search="search" :items="rows" class="elevation-0"
                    item-key="loan_id">
                    <template v-slot:[`item.loan_date`]="{ item }">
                        <div>
                            {{ new Date(item.loan_date).toISOString().split('T')[0] }}
                        </div>
                    </template>

                    <template v-slot:[`item.loan_amount`]="{ item }">
                        <div>
                            {{ (item.loan_amount).toLocaleString() }}
                            {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }}
                        </div>
                    </template>

                    <template v-slot:[`item.loan_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/loans-list/'+item.loan_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectLoans(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteLoansList">{{$store.getters.language.data.loans.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.loans.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.loans.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteLoans(selected_loans.loan_id)">
                {{$store.getters.language.data.loans.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/loans.request.js'
    export default {
        data() {
            return {
                loans: {},
                search: '',
                loading : false,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                selected_loans : {},
                delete_dialog: false,
                headers: [
                    { 
                        text: this.$store.getters.language.data.loans.customer_name,
                        align: 'start',
                        sortable: true,
                        value: 'customer_name',
                    },
                    { 
                        text: this.$store.getters.language.data.loans.user_name,
                        align: 'start',
                        sortable: true,
                        value: 'user_name',
                    },
                    { 
                        text: this.$store.getters.language.data.loans.account_name,
                        align: 'start',
                        sortable: true,
                        value: 'account_name',
                    },
                    { 
                        text: this.$store.getters.language.data.loans.loan_date,
                        align: 'start',
                        sortable: true,
                        value: 'loan_date',
                    },
                    { 
                        text: this.$store.getters.language.data.loans.loan_amount,
                        align: 'start',
                        sortable: true,
                        value: 'loan_amount',
                    },
                    { 
                        text: this.$store.getters.language.data.loans.loan_note,
                        align: 'start',
                        sortable: true,
                        value: 'loan_note',
                    },   
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'loan_id',
                    }
                ],
            }
        },
        computed: {
            customers(){
                return this.$store.getters.customers_list
            },
            users(){
                return this.$store.getters.users_list
            },
            accounts() {
                return this.$store.getters.accounts_list
            },
            buttonDisable() {
                return !this.loans.customer_id ||
                    !this.loans.user_id ||
                    !this.loans.account_id ||
                    !this.loans.loan_date ||
                    !this.loans.loan_amount 
            }
        },
        mounted(){
            this.loans.user_id = this.$store.getters.auth.user_id;
            this.loans.loan_date = new Date().toISOString().split('T')[0]
            this.loans.account_id = this.accounts.find(obj => obj.account_is_default == 'true').account_id;
        },
        methods: {
            addLoans() {
                this.loading_btn = true
                requests.createLoans(this.loans).then(r => {
                    if (r.status == 200) {
                        this.loans = {
                            user_id: this.$store.getters.auth.user_id,
                            account_id: this.accounts.find(obj => obj.account_is_default == 'true').account_id
                        }
                        this.rows.push(r.data.new_data)
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.add_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteLoans(loan_id) {
                requests.deleteLoans(loan_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.loan_id != loan_id
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteLoansList() {
                let ids = this.selected_rows.map(m => m.loan_id)
                requests.deleteLoansList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.loan_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            
            readLoans() {
                this.loading = true
                if(this.loans.customer_id) {
                    requests.reportLoans(`store_id=${this.$store.getters.store.store_id}&customer_id=${this.loans.customer_id}`).then(r => {
                        if (r.status == 200) {
                            this.rows = r.data
                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: this.$store.getters.language.data.messages.failed,
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
                } else {
                    this.rows = [];
                    this.loading = false
                }
            },
            selectLoans(i) {
                this.selected_loans = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    